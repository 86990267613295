<template>
  <div align="center">
    <br>
    <div class="card col-md-8 rounded shadow-lg" align="left">
      <div class="card-body">
        <div class="card-title">
          <h1>Migrar datos</h1>
        </div>
        <br>
        <form>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Dependencias:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('dependencias')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Estados:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('estados')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Índices:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('indices')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Salas:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('salas')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Series:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('series')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Tipos de tabla (Agregar manualmente):</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Versiones de tabla:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('versionestabla')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Tablas:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('tablas')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Tipos de documento:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('tpodocs')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Estantes (Actualizar esta página(salas):</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('estantes')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Tipos de archivo (Agregar manualmente):</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Cajas (Actualizar esta página(estantes):</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('cajas')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Unidades de conservación (Agregar manualmente):</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Inventarios (Actualizar esta página(cajas):</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('legajos')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Usuarios (Revisar duplicados, Cambiar Tipos de usuario):</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('usuarios')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Clases de documento (Agregar manualmente):</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Permisos usuario por dependencia y serie - Correspondencia (Actualizar esta página (Usuarios)):</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('usuxrads')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Permisos usuario por dependencia y serie - Archivo (Actualizar esta página (Usuarios)):</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('usuxarchs')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Permisos usuario por sala (Actualizar página (Usuarios)):</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('usuxsals')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Documentos:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatos('documentos')">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Documentos secundario:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatosSecundarios">Iniciar</button>
              </div>
            </div>
          </div>
          <!--
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Documentos salas:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatosSalas">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Documentos respuesta:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatosRespuesta">Iniciar</button>
              </div>
            </div>
          </div>
          -->
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Documentos - Índices:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatosIndices">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Documentos - Seguimiento:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatosSeguimiento">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Documentos - Adjuntos:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatosAdjuntos">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Documentos - Archivos:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarDatosArchivos">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Documentos - Convertir tiff2pdf:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarTiff2Pdf">Iniciar</button>
              </div>
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Parámetros:</label>
                <label class="col-md-2 col-form-label">Límite 1:</label>
                <input type="number" min="0" class="form-control col-md-4" v-model="limit1">
              </div>
              <div class="form-group row">
                <label class="col-md-6 col-form-label"></label>
                <label class="col-md-2 col-form-label">Límite 2:</label>
                <input type="number" min="0" class="form-control col-md-4" v-model="limit2">
              </div>
              <div class="form-group row">
                <label class="col-md-6 col-form-label"></label>
                <label class="col-md-2 col-form-label">Monocromo:</label>
                <input type="checkbox" class="form-control col-md-4" v-model="monochrome">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Documentos - Eliminar tiff convertidos:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarEliminarTiff">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Sincronizar repositorio (En servidor Siged):</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Documentos - Cambiar nombres tiff a pdf (En servidor Siged):</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarChangeNameTiff2Pdf">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Cambiar nombres de archivos Contratos Comfacasanare:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="migrarChangeNameComfacasanare">Iniciar</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-6 col-form-label">Documentos - Cargar archivos adjuntos:</label>
                <button type="button" class="btn btn-success" v-on:click.prevent="cargarAdjuntos">Iniciar</button>
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label"></label>
                <label class="col-md-4 col-form-label">Clase documento:</label>
                <select v-model="adj_tcr_id" ref='adj_tcr_id' class="form-control col-md-4">
                  <option v-for="tpocor in tpocors" v-bind:value="tpocor._id">
                    {{ tpocor.tcr_nmbre }}
                  </option>
                </select>
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label"></label>
                <label class="col-md-4 col-form-label">Año:</label>
                <input type="number" min="0" class="form-control col-md-4" v-model="adj_dcm_ano">
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label"></label>
                <label class="col-md-4 col-form-label">Límite 1:</label>
                <input type="number" min="0" class="form-control col-md-4" v-model="adjuntos1">
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label"></label>
                <label class="col-md-4 col-form-label">Límite 2:</label>
                <input type="number" min="0" class="form-control col-md-4" v-model="adjuntos2">
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label"></label>
                <label class="col-md-4 col-form-label">Carpeta:</label>
                <input type="text" class="form-control col-md-4" v-model="carpeta">
              </div>
            </div>
          </div>

          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
            <p id="time" class="warning"></p>
            <p>{{ error }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-4">
              <router-link :to="{ name: 'Home' }" class="btn btn-secondary">Cancelar</router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
    <p>CAMBIOS: {{ cambios }}</p>
    <p>TIFFS NO EXISTEN: {{ errores }}</p>
    <p>TIFFS NO CONVIRTIERON: {{ errores_conversion }}</p>    
    <p>TIFFS ELIMINADOS: {{ tiff_eliminados }}</p>    
  </div>
</template>

<script>
  //import toastr from 'toastr';
  import moment from 'moment';

  import { wait } from '../../utils';

  //feather.replace();

  export default {
    data(){
      return{
        item: {},
        datos: [],
        message: '',
        errores: [],
        errores_conversion: [],
        tiff_eliminados: [],
        tpotablas: [],
        dependencias: [],
        series: [],
        tpousus: [],
        tpocors: [],
        tpodocs: [],
        usuarios: [],
        tpoarchs: [],
        indices: [],
        estados: [],
        salas: [],
        estantes: [],
        cajas: [],
        uconservs: [],
        count: 0,
        cambios: [],
        limit1: 0,
        limit2: 0,
        monochrome: false,
        error: '',
        adj_tcr_id: null,
        adj_dcm_ano: 0,
        adjuntos1: 0,
        adjuntos2: 0,
        carpeta: ''
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      }
    },
    created: function() {
      this.fetchTpotabla();
      this.fetchDependencia();
      this.fetchSerie();
      this.fetchTpousu();
      this.fetchTpocor();
      this.fetchTpodoc();
      this.fetchUsuario();
      this.fetchTpoarch();
      this.fetchIndice();
      this.fetchEstado();
      this.fetchSala();
      this.fetchEstante();
      this.fetchCaja();
      this.fetchUconserv();

    },
    methods: {
      fetchTpotabla()
      {
        let uri = '/tpotablas/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.tpotablas = response.data;
        });
      },
      fetchDependencia()
      {
        let uri = '/deps/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.dependencias = response.data;
        });
      },
      fetchSerie()
      {
        let uri = '/series/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.series = response.data;
        });
      },
      fetchTpousu()
      {
        let uri = '/tpousus';
        this.axios.get(uri).then((response) => {
          this.tpousus = response.data;
        });
      },
      fetchTpocor()
      {
        let uri = '/tpocors/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.tpocors = response.data;
        });
      },
      fetchTpodoc()
      {
        let uri = '/tpodocs/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.tpodocs = response.data;
        });
      },
      fetchUsuario()
      {
        let uri = '/usuarios/' + String(this.$store.state.company);
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.get(uri).then((response) => {
          this.usuarios = response.data;
        });
      },
      fetchTpoarch()
      {
        let uri = '/tpoarchs/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.tpoarchs = response.data;
        });
      },
      fetchIndice()
      {
        let uri = '/indices/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.indices = response.data;
        });
      },
      fetchEstado()
      {
        let uri = '/estados/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.estados = response.data;
        });
      },
      fetchSala()
      {
        let uri = '/salas/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.salas = response.data;
        });
      },
      fetchEstante()
      {
        let uri = '/estantes/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.estantes = response.data;
        });
      },
      fetchCaja()
      {
        let uri = '/cajas/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.cajas = response.data;
        });
      },
      fetchUconserv()
      {
        let uri = '/uconservs/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.uconservs = response.data;
        });
      },
      migrarDatos(tabla){
        this.message = 'Iniciando.. ' + tabla;
        this.item.cmp_id = String(this.$store.state.company);
        var tablasql = '';

        var g = 0; //Registros guardados;

        switch(tabla){
          case 'dependencias':
            tablasql = 'depdcias';
            break;
          case 'estados':
            tablasql = 'Estado';
            break;
          case 'indices':
            tablasql = 'indices';
            break;
          case 'salas':
            tablasql = 'salas';
            break;
          case 'estantes':
            tablasql = 'estantes';
            break;
          case 'cajas':
            tablasql = 'cajas';
            break;
          case 'legajos':
            tablasql = 'legajos';
            break;
          case 'series':
            tablasql = 'series';
            break;
          case 'versionestabla':
            tablasql = 'tablas_version';
            break;
          case 'tablas':
            tablasql = 'tablas';
            break;
          case 'tpodocs':
            tablasql = 'tpodocs';
            break;
          case 'tpousus':
            tablasql = 'tpousus';
            break;
          case 'usuarios':
            tablasql = 'usuarios';
            break;
          case 'usuxrads':
            tablasql = 'usuxrad';
            break;
          case 'usuxarchs':
            tablasql = 'usuxarch';
            break;
          case 'usuxsals':
            tablasql = 'usuxsal';
            break;
          case 'documentos':
            tablasql = 'dcmnts';
            break;
        }

        //alert(tablasql);
        var uri = '/sql/' + tablasql;

        //alert(this.item);
        this.axios.get(uri)
        .then(response => {
          var datos = response.data;

          this.errores = [];
          var i = 0;

          this.loopArray(tabla, datos, i);

          this.message = 'Datos recibidos: ' + tabla + '. Se guardaron: ' + g + '/' + datos.length;
        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al consultar los datos: ' + tabla + ' // ' + err;
        });
      },
      async loopArray(tabla, datos, i){
        var tta_id = '';
        var dep_id = '';
        var ser_id = '';
        var tus_id = '';
        var tcr_id = '';
        var tdo_id = '';
        var usu_id = '';
        var tpa_id = '';
        var sal_id = '';
        var est_id = '';
        var caj_id = '';
        var uco_id = '';
        var dcm_id = '';
        var usu_admin = '';
        var uri = '';

        var item = {};
        switch(tabla){
          case 'dependencias':
            item.cmp_id = String(this.$store.state.company);
            item.dep_cdgo = datos[i].dep_cdgo;
            item.dep_nmbre = datos[i].dep_nmbre;
            item.RepartoUsuario = datos[i].RepartoUsuario;
            uri = '/deps/add';
            break;
          case 'estados':
            item.cmp_id = String(this.$store.state.company);
            item.EstadoId = datos[i].EstadoId;
            item.Nombre = datos[i].Nombre;
            item.Nivel = datos[i].Nivel;
            uri = '/estados/add';
            break;
          case 'indices':
            item.cmp_id = String(this.$store.state.company);
            item.ind_cdgo = datos[i].ind_cdgo;
            item.ind_nmbre = datos[i].ind_nmbre;
            item.ind_dscrpcion = datos[i].ind_dscrpcn;
            item.ind_unico = datos[i].ind_unico;
            uri = '/indices/add';
            break;
          case 'salas':
            item.cmp_id = String(this.$store.state.company);
            item.sal_cdgo = datos[i].sal_cdgo;
            item.sal_nmbre = datos[i].sal_nmbre;
            uri = '/salas/add';
            break;
          case 'estantes':
            item.cmp_id = String(this.$store.state.company);
            sal_id = '';
            for (var j = 0; j < this.salas.length; j++){
              if (datos[i].sal_cdgo == this.salas[j].sal_cdgo){
                sal_id = this.salas[j]._id; //***IMPORTANTE _id
                j = this.salas.length;
              }
            }
            item.sal_id = sal_id;

            item.est_cdgo = datos[i].est_cdgo;
            item.est_cjasxb = datos[i].est_cjasxb;
            item.est_bndjas = datos[i].est_bndjas;
            uri = '/estantes/add';
            break;
          case 'cajas':
            item.cmp_id = String(this.$store.state.company);
            sal_id = '';
            for (var j = 0; j < this.salas.length; j++){
              if (datos[i].sal_cdgo == this.salas[j].sal_cdgo){
                sal_id = this.salas[j]._id; //***IMPORTANTE _id
                j = this.salas.length;
              }
            }
            item.sal_id = sal_id;

            tpa_id = '';
            for (var j = 0; j < this.tpoarchs.length; j++){
              if (datos[i].tpa_cdgo == this.tpoarchs[j].tpa_cdgo){
                tpa_id = this.tpoarchs[j]._id; //***IMPORTANTE _id
                j = this.tpoarchs.length;
              }
            }
            item.tpa_id = tpa_id;

            est_id = '';
            for (var j = 0; j < this.estantes.length; j++){
              if (item.sal_id == this.estantes[j].sal_id && datos[i].est_cdgo == this.estantes[j].est_cdgo){
                est_id = this.estantes[j]._id; //***IMPORTANTE _id
                j = this.estantes.length;
              }
            }
            item.est_id = est_id;

            item.caj_cdgo = datos[i].caj_cdgo;
            item.caj_bndja = datos[i].caj_bndja;
            uri = '/cajas/add';
            break;
          case 'legajos':
            item.cmp_id = String(this.$store.state.company);
            sal_id = '';
            for (var j = 0; j < this.salas.length; j++){
              if (datos[i].sal_cdgo == this.salas[j].sal_cdgo){
                sal_id = this.salas[j]._id; //***IMPORTANTE _id
                j = this.salas.length;
              }
            }
            //item.sal_id = sal_id;

            tpa_id = '';
            for (var j = 0; j < this.tpoarchs.length; j++){
              if (datos[i].tpa_cdgo == this.tpoarchs[j].tpa_cdgo){
                tpa_id = this.tpoarchs[j]._id; //***IMPORTANTE _id
                j = this.tpoarchs.length;
              }
            }
            //item.tpa_id = tpa_id;

            caj_id = '';
            for (var j = 0; j < this.cajas.length; j++){
              if (sal_id == this.cajas[j].sal_id && tpa_id == this.cajas[j].tpa_id && datos[i].caj_cdgo == this.cajas[j].caj_cdgo){
                caj_id = this.cajas[j]._id; //***IMPORTANTE _id
                j = this.cajas.length;
              }
            }
            item.caj_id = caj_id;

            item.lgj_nmro = datos[i].lgj_nmro;
            uco_id = '';
            for (var j = 0; j < this.uconservs.length; j++){
              if (datos[i].uco_cdgo == this.uconservs[j].uco_cdgo){
                uco_id = this.uconservs[j]._id; //***IMPORTANTE _id
                j = this.uconservs.length;
              }
            }
            item.uco_id = uco_id;

            uri = '/legajos/add';
            break;
          case 'series':
            item.cmp_id = String(this.$store.state.company);
            item.ser_cdgo = datos[i].ser_cdgo;
            item.ser_nmbre = datos[i].ser_nmbre;
            item.ser_nodo = datos[i].ser_nodo;
            item.ser_tres = datos[i].ser_tres;
            item.ind_cdgo = datos[i].ind_cdgo;
            uri = '/series/add';
            break;
          case 'versionestabla':
            item.cmp_id = String(this.$store.state.company);
            tta_id = '';
            for (var j = 0; j < this.tpotablas.length; j++){
              if (datos[i].tta_cdgo == this.tpotablas[j].tta_cdgo){
                tta_id = this.tpotablas[j]._id; //***IMPORTANTE _id
                j = this.tpotablas.length;
              }
            }
            item.tta_id = tta_id;
            item.tav_nmro = datos[i].tav_nmro;
            item.tav_inicio = datos[i].tav_inicio;
            item.tav_acta = datos[i].tav_acta;
            item.tav_descripcion = datos[i].tav_descripcion;
            uri = '/tabvers/add';
            break;
          case 'tablas':
            item.cmp_id = String(this.$store.state.company);

            tta_id = '';
            for (var j = 0; j < this.tpotablas.length; j++){
              if (datos[i].tta_cdgo == this.tpotablas[j].tta_cdgo){
                tta_id = this.tpotablas[j]._id; //***IMPORTANTE _id
                j = this.tpotablas.length;
              }
            }
            item.tta_id = tta_id;

            item.tav_nmro = datos[i].tav_nmro;

            dep_id = '';
            for (var j = 0; j < this.dependencias.length; j++){
              if (datos[i].dep_cdgo == this.dependencias[j].dep_cdgo){
                dep_id = this.dependencias[j]._id; //***IMPORTANTE _id
                j = this.dependencias.length;
              }
            }
            item.dep_id = dep_id;

            ser_id = '';
            for (var j = 0; j < this.series.length; j++){
              if (datos[i].ser_cdgo == this.series[j].ser_cdgo){
                ser_id = this.series[j]._id; //***IMPORTANTE _id
                j = this.series.length;
              }
            }
            item.ser_id = ser_id;

            item.tab_tpg = datos[i].tab_tpg;
            item.tab_tpc = datos[i].tab_tpc;
            item.tab_cnsrvcn = datos[i].tab_cnsrvcn;
            item.tab_elmncn = datos[i].tab_elmncn;
            item.tab_dgtlzcn = datos[i].tab_dgtlzcn;
            item.tab_slccn = datos[i].tab_slccn;
            item.tab_prcdmnto = datos[i].tab_prcdmnto;
            item.tab_activa = datos[i].tab_activa;
            item.tab_cph = datos[i].tab_cph;
            uri = '/tablas/add';
            break;
          case 'tpodocs':
            item.cmp_id = String(this.$store.state.company);
            item.tdo_cdgo = datos[i].tdo_cdgo;
            item.tdo_nmbre = datos[i].tdo_nmbre;
            uri = '/tpodocs/add';
            break;
          case 'tpousus':
            item.cmp_id = String(this.$store.state.company);
            item.tus_cdgo = datos[i].tus_cdgo;
            item.tus_nmbre = datos[i].tus_nmbre;
            uri = '/tpousus/add';
            break;
          case 'usuarios':
            item.cmp_id = String(this.$store.state.company);
            item.usu_cdgo = datos[i].usu_cdgo;
            item.usu_nmbre = datos[i].usu_nmbre;

            tus_id = '';
            for (var j = 0; j < this.tpousus.length; j++){
              //if (datos[i].tus_cdgo == this.tpousus[j].tus_cdgo){
              if (3 == this.tpousus[j].tus_cdgo){ //Usuario oficina para todos
                tus_id = this.tpousus[j]._id; //***IMPORTANTE _id
                j = this.tpousus.length;
              }
            }
            item.tus_id = tus_id;

            item.usu_pwd = item.usu_cdgo + '20%'; // datos[i].usu_pwd;
            uri = '/usuarios/add';
            break;
          case 'usuxrads':
            item.cmp_id = String(this.$store.state.company);

            usu_id = '';
            for (var j = 0; j < this.usuarios.length; j++){
              if (datos[i].usu_cdgo.trim() == this.usuarios[j].usu_cdgo){
                usu_id = this.usuarios[j]._id; //***IMPORTANTE _id
                j = this.usuarios.length;
              }
            }
            item.usu_id = usu_id;

            dep_id = '';
            for (var j = 0; j < this.dependencias.length; j++){
              if (datos[i].dep_cdgo == this.dependencias[j].dep_cdgo){
                dep_id = this.dependencias[j]._id; //***IMPORTANTE _id
                j = this.dependencias.length;
              }
            }
            if (dep_id == ''){
              alert('No se encontró la dependencia: ' + datos[i].dep_cdgo);
            }
            item.dep_id = dep_id;

            ser_id = '';
            for (var j = 0; j < this.series.length; j++){
              if (datos[i].ser_cdgo == this.series[j].ser_cdgo){
                ser_id = this.series[j]._id; //***IMPORTANTE _id
                j = this.series.length;
              }
            }
            item.ser_id = ser_id;

            tcr_id = '';
            for (var j = 0; j < this.tpocors.length; j++){
              if (datos[i].tcr_cdgo == this.tpocors[j].tcr_cdgo){
                tcr_id = this.tpocors[j]._id; //***IMPORTANTE _id
                j = this.tpocors.length;
              }
            }
            item.tcr_id = tcr_id;

            item.uxr_ver = true;
            item.uxr_mdfcr = datos[i].uxr_mdfcr;
            item.uxr_imprmr = datos[i].uxr_imprmr;
            item.uxr_mdfcr_otros = datos[i].uxr_mdfcr_otros;
            item.uxr_archvr = true;

            uri = '/uxr/add';
            break;
          case 'usuxarchs':
            item.cmp_id = String(this.$store.state.company);

            usu_id = '';
            for (var j = 0; j < this.usuarios.length; j++){
              if (datos[i].usu_cdgo.trim() == this.usuarios[j].usu_cdgo){
                usu_id = this.usuarios[j]._id; //***IMPORTANTE _id
                j = this.usuarios.length;
              }
            }
            item.usu_id = usu_id;

            dep_id = '';
            for (var j = 0; j < this.dependencias.length; j++){
              if (datos[i].dep_cdgo == this.dependencias[j].dep_cdgo){
                dep_id = this.dependencias[j]._id; //***IMPORTANTE _id
                j = this.dependencias.length;
              }
            }
            if (dep_id == ''){
              alert('No se encontró la dependencia: ' + datos[i].dep_cdgo);
            }
            item.dep_id = dep_id;

            ser_id = '';
            for (var j = 0; j < this.series.length; j++){
              if (datos[i].ser_cdgo == this.series[j].ser_cdgo){
                ser_id = this.series[j]._id; //***IMPORTANTE _id
                j = this.series.length;
              }
            }
            item.ser_id = ser_id;

            tcr_id = '';
            for (var j = 0; j < this.tpocors.length; j++){
              if (datos[i].tcr_cdgo == this.tpocors[j].tcr_cdgo){
                tcr_id = this.tpocors[j]._id; //***IMPORTANTE _id
                j = this.tpocors.length;
              }
            }
            item.tcr_id = tcr_id;

            item.uxr_ver = true;
            item.uxr_mdfcr = datos[i].uxa_mdfcr;
            item.uxr_imprmr = datos[i].uxa_imprmr;
            item.uxr_mdfcr_otros = datos[i].uxa_mdfcr;
            item.uxr_archvr = datos[i].uxa_mdfcr;

            uri = '/uxr/add';
            break;
          case 'usuxsals':
            item.cmp_id = String(this.$store.state.company);

            usu_id = '';
            for (var j = 0; j < this.usuarios.length; j++){
              if (datos[i].usu_cdgo.trim() == this.usuarios[j].usu_cdgo){
                usu_id = this.usuarios[j]._id; //***IMPORTANTE _id
                j = this.usuarios.length;
              }
            }
            item.usu_id = usu_id;

            sal_id = '';
            for (var j = 0; j < this.salas.length; j++){
              if (datos[i].sal_cdgo == this.salas[j].sal_cdgo){
                sal_id = this.salas[j]._id; //***IMPORTANTE _id
                j = this.salas.length;
              }
            }
            if (sal_id == ''){
              alert('No se encontró la sala: ' + datos[i].sal_cdgo);
            }
            item.sal_id = sal_id;

            item.uxs_mdfcr = datos[i].uxs_mdfcr;

            uri = '/uxs/add';
            break;
          case 'documentos':
            item.cmp_id = String(this.$store.state.company);

            var route = '/docs/searchbynmro';

            var response = await this.axios.post(route, { 'cmp_id': this.$store.state.company, 'dcm_nmro': datos[i].dcm_nmro })

            console.log(JSON.stringify(response));

            if (response?.data?._id){
              uri = '';
            } else {
              usu_admin = '';
              for (var j = 0; j < this.usuarios.length; j++){
                if ('sa' == this.usuarios[j].usu_cdgo){
                  usu_admin = this.usuarios[j]._id; //***IMPORTANTE _id
                  j = this.usuarios.length;
                }
              }

              tcr_id = '';
              for (var j = 0; j < this.tpocors.length; j++){
                if (datos[i].tcr_cdgo == this.tpocors[j].tcr_cdgo){
                  tcr_id = this.tpocors[j]._id; //***IMPORTANTE _id
                  j = this.tpocors.length;
                }
              }
              if (tcr_id == ''){
                //alert(datos[i].tcr_cdgo);
              }
              item.tcr_id = tcr_id;

              item.dcm_ano = datos[i].dcm_ano;
              item.dcm_cdgo = datos[i].dcm_cdgo;
              var d = new Date(datos[i].dcm_fcharad);
              if(d.getTimezoneOffset() > 0){
                  d.setTime(d.getTime() + d.getTimezoneOffset()*60*1000);
              }
              item.dcm_fcharad = d;

              tdo_id = '';
              for (var j = 0; j < this.tpodocs.length; j++){
                if (datos[i].tdo_cdgo == this.tpodocs[j].tdo_cdgo){
                  tdo_id = this.tpodocs[j]._id; //***IMPORTANTE _id
                  j = this.tpodocs.length;
                }
              }
              item.tdo_id = tdo_id;

              tta_id = '';
              for (var j = 0; j < this.tpotablas.length; j++){
                if (datos[i].tta_cdgo == this.tpotablas[j].tta_cdgo){
                  tta_id = this.tpotablas[j]._id; //***IMPORTANTE _id
                  j = this.tpotablas.length;
                }
              }
              item.tta_id = tta_id;

              dep_id = '';
              for (var j = 0; j < this.dependencias.length; j++){
                if (datos[i].dep_cdgo == this.dependencias[j].dep_cdgo){
                  dep_id = this.dependencias[j]._id; //***IMPORTANTE _id
                  j = this.dependencias.length;
                }
              }
              item.dep_id = dep_id;

              ser_id = '';
              for (var j = 0; j < this.series.length; j++){
                if (datos[i].ser_cdgo == this.series[j].ser_cdgo){
                  ser_id = this.series[j]._id; //***IMPORTANTE _id
                  j = this.series.length;
                }
              }
              item.ser_id = ser_id;

              item.dcm_fchaini = datos[i].dcm_fchaini;
              item.dcm_fchafin = datos[i].dcm_fchafin;
              item.dcm_electronico = false;

              tpa_id = '';
              for (var j = 0; j < this.tpoarchs.length; j++){
                if (datos[i].tpa_cdgo == this.tpoarchs[j].tpa_cdgo){
                  tpa_id = this.tpoarchs[j]._id; //***IMPORTANTE _id
                  j = this.tpoarchs.length;
                }
              }
              item.tpa_id = tpa_id;

              if (datos[i].sal_cdgo){
                item.dcm_archivado = true;

                sal_id = '';
                for (var j = 0; j < this.salas.length; j++){
                  if (datos[i].sal_cdgo == this.salas[j].sal_cdgo){
                    sal_id = this.salas[j]._id; //***IMPORTANTE _id
                    j = this.salas.length;
                  }
                }
                if (sal_id != ''){
                  item.sal_id = sal_id;
                }

                item.caj_cdgo = datos[i].caj_cdgo;
                item.lgj_nmro = datos[i].lgj_nmro;
              } else {
                item.dcm_archivado = false;
              }

              item.dcm_nflios = datos[i].dcm_nflios;;

              usu_id = '';
              for (var j = 0; j < this.usuarios.length; j++){
                if (datos[i].usu_cdgo.trim() == this.usuarios[j].usu_cdgo){
                  usu_id = this.usuarios[j]._id; //***IMPORTANTE _id
                  j = this.usuarios.length;
                }
              }
              if (usu_id == ''){
                usu_id = usu_admin;
                //alert(usu_id);
              }
              item.usu_id = usu_id;

              item.dcm_rspsta = datos[i].dcm_rspsta;
              item.dcm_soportes = datos[i].dcm_soportes;
              item.dcm_nmro = datos[i].dcm_nmro;
              item.dcm_cph = datos[i].dcm_cph;
              item.dcm_phcam = datos[i].dcm_phcam;

              //item.dcm_expediente = datos[i].dcm_rlcion;
              //item.dcm_rlcion = datos[i].dcm_rlcion;
              item.dcm_rlcion_anterior = datos[i].dcm_rlcion;

              item.tav_nmro = datos[i].tav_nmro;

              //item.dcm_entidad = dcm_entidad;
              //item.dcm_ciudad = dcm_ciudad;
              //item.dcm_asunto = dcm_asunto;
              //item.dcm_anexos = dcm_anexos;

              var uri = '/docs/addmigrated';              
            } 
            break;
        }
        //alert(item);
        debugger;
        if (uri != ''){
          this.axios.post(uri, item)
          //.then(wait(1000))
          .then((response) => {
            i++;
            this.message = 'Registro [' + i + '/' + datos.length + '] guardado';

            if (i < datos.length){
              this.loopArray(tabla, datos, i);
            }
          })
          .catch((err) => {
            i++;
            this.message = '¡Error al guardar el registro[' + i + '/' + datos.length + ']! // ' + err;
            this.errores.push(err);

            if (i < datos.length){
              this.loopArray(tabla, datos, i);
            }
          });
        } else {
          i++;
          this.message = 'Registro [' + i + '/' + datos.length + '] ya existe.';

          if (i < datos.length){
            this.loopArray(tabla, datos, i);
          }
        }
      },
      migrarDatosSecundarios(){
        this.errores = [];
        this.message = 'Iniciando documentos secundarios!';
        var uri = '/docs/searchmigrated/' + this.$store.state.company;

        this.axios.get(uri)
        .then((response) => {
          var docs = response.data;
          var i = 0;

          if (docs.length > 0){
            const start = (docs, i) => {
                if (docs[i].dcm_expediente == undefined){
                  uri = '/docs/searchbynmro';

                  this.axios.post(uri, { 'cmp_id': this.$store.state.company, 'dcm_nmro': docs[i].dcm_rlcion_anterior })
                  .then((response) => {
                    if (response){
                      let dcm_expediente = response.data._id; //***IMPORTANTE _id
                      let dcm_rlcion = response.data._id; //***IMPORTANTE _id

                      uri = '/docs/updatemigrated/' + docs[i]._id;

                      this.axios.post(uri, { 'dcm_nmro': docs[i].dcm_nmro, 'dcm_expediente': dcm_expediente, 'dcm_rlcion': dcm_rlcion })
                      .then((response) => {
                        i++;
                        this.message = 'Registro [' + i + '/' + docs.length + '] actualizado';

                        if (i < docs.length){
                          start(docs, i);
                        }
                      })
                      .catch((err) => {
                        i++;
                        this.message = '¡Error al guardar el registro[' + i + '/' + docs.length + ']! // ' + err;
                        this.errores.push(err);

                        if (i < docs.length){
                          start(docs, i);
                        }
                      });
                    } else {
                      i++;
                      this.message = 'Registro [' + i + '/' + docs.length + '] procesado';

                      if (i < docs.length){
                        start(docs, i);
                      }
                    }
                  })
                  .catch((err) => {
                    i++;
                    this.message = '¡Error al guardar el registro[' + i + '/' + docs.length + ']! // ' + err;
                    this.errores.push(err);

                    if (i < docs.length){
                      start(docs, i);
                    }
                  });                
                } else {
                  //docs[i].dcm_expediente = null; //docs[i]._id;
                  //docs[i].dcm_rlcion = null;
                  i++;
                  this.message = 'Registro [' + i + '/' + docs.length + '] guardado';

                  if (i < docs.length){
                    start(docs, i);
                  }
                }
            }

            start(docs, i);

            //this.message = 'Se actualizaron los datos secundarios!';
            
          } else {
            this.message = 'Fin. No se han encontrado documentos relacionados';
          }
        })
        .catch((err) => {
          this.message = 'Error la actualizar los datos secundarios!' + err;
        });

      },
      migrarDatosIndices(){
        this.errores = [];
        this.message = 'Iniciando documentos - indices!';
        var uri = '/docs/searchmigratedindices/' + this.$store.state.company;

        this.axios.get(uri)
        .then((response) => {
          var docs = response.data;
          var i = 0;

          const start = (docs, i) => {
              if (docs[i].dcm_nmro != undefined){
                uri = '/sql/inddoc';

                this.axios.post(uri, { 'dcm_nmro': docs[i].dcm_nmro })
                .then((response) => {
                  var ind = response.data;

                  if (ind.length > 0){
                    var indices = [];
                    for (let j = 0; j < ind.length; j++){
                      var ind_id = '';
                      for (let k = 0; k < this.indices.length; k++){
                        if (ind[j].ind_cdgo == this.indices[k].ind_cdgo){
                          ind_id = this.indices[k]._id;
                          k = this.indices.length;
                        }
                      }
                      if (ind_id != ''){
                        indices.push({ 'ind_id': ind_id, 'ixd_valor': ind[j].idc_valor, 'ovn_cdgo': ind[j].ovn_cdgo })
                      }
                    }

                    uri = '/docs/updateinddoc';

                    this.axios.post(uri, { 'index': i/docs.length*100, 'cmp_id': this.$store.state.company, '_id': docs[i]._id, 'dcm_nmro': docs[i].dcm_nmro, 'indices': indices })
                    .then((response) => {
                      i++;
                      this.message = 'Registro [' + i + '/' + docs.length + '] actualizado';

                      if (i < docs.length){
                        start(docs, i);
                      }
                    })
                    .catch((err) => {
                      i++;
                      this.message = '¡Error al guardar el registro[' + i + '/' + docs.length + ']! // ' + err;
                      this.errores.push(err);

                      if (i < docs.length){
                        start(docs, i);
                      }
                    });
                  } else {
                    i++;
                    this.message = 'Registro [' + i + '/' + docs.length + '] procesado';

                    if (i < docs.length){
                      start(docs, i);
                    }
                  }
                })
                .catch((err) => {
                  i++;
                  this.message = '¡Error al guardar el registro[' + i + '/' + docs.length + ']! // ' + err;
                  this.errores.push(err);

                  if (i < docs.length){
                    start(docs, i);
                  }
                });                
              } else {
                //docs[i].dcm_expediente = null; //docs[i]._id;
                //docs[i].dcm_rlcion = null;
                i++;
                this.message = 'Registro [' + i + '/' + docs.length + '] guardado';

                if (i < docs.length){
                  start(docs, i);
                }
              }
          }

          start(docs, i);

          //this.message = 'Se actualizaron los datos secundarios!';
        })
        .catch((err) => {
          this.message = 'Error la actualizar los datos secundarios!' + err;
        });

      },
      migrarDatosSeguimiento(){
        this.errores = [];
        this.message = 'Iniciando documentos - seguimiento!';
        var uri = '/sql/seguimiento';

        this.axios.get(uri)
        .then((response) => {
          var segs = response.data;
          var i = 0;

          const start = (segs, i) => {
            var deUsuario = false;
            var paraUsuario = false;
            var p = {};

            p.index = i/segs.length*100;
            p.cmp_id = this.$store.state.company;
            p.dcm_nmro = segs[i].dcm_nmro;
            p.Fecha = segs[i].Fecha;
            p.Descripcion = segs[i].Descripcion;

            for (let j = 0; j < this.usuarios.length; j++){
              if (segs[i].DeUsuarioId.toLowerCase() == this.usuarios[j].usu_cdgo.toLowerCase()){
                p.DeUsuarioId = this.usuarios[j]._id;
                j = this.usuarios.length;
              }
            }

            for (let j = 0; j < this.usuarios.length; j++){
              if (segs[i].ParaUsuarioId.toLowerCase() == this.usuarios[j].usu_cdgo.toLowerCase()){
                p.ParaUsuarioId = this.usuarios[j]._id;
                j = this.usuarios.length;
              }
            }

            for (let j = 0; j < this.estados.length; j++){
              if (segs[i].EstadoId == this.estados[j].EstadoId){
                p.EstadoId = this.estados[j]._id;
                j = this.estados.length;
              }
            }

            uri = '/docs/updateseguimiento';

            this.axios.post(uri, p)
            .then((response) => {
              i++;
              this.message = 'Registro [' + i + '/' + segs.length + '] guardado';
              if (i < segs.length){
                start(segs, i);
              }
            })
            .catch((err) => {
              i++;
              this.message = '¡Error al guardar el registro[' + i + '/' + segs.length + ']! // ' + err;
              this.errores.push(err);
              if (i < segs.length){
                start(segs, i);
              }
            });
          }

          start(segs, i);

          //this.message = 'Se actualizaron los datos secundarios!';

        })
        .catch((err) => {
          this.message = 'Error al actualizar los datos de seguimiento de documentos!' + err;
        });
      },
      migrarDatosAdjuntos(){
        this.errores = [];
        this.message = 'Iniciando documentos - adjuntos!';
        var uri = '/docs/searchmigratedadjuntos/' + this.$store.state.company;

        this.axios.get(uri)
        .then((response) => {
          var docs = response.data;
          var i = 0;

          const start = (docs, i) => {
              if (docs[i].dcm_nmro != undefined){
                uri = '/sql/adjdoc';

                this.axios.post(uri, { 'dcm_nmro': docs[i].dcm_nmro })
                .then((response) => {
                  var adj = response.data;

                  if (adj.length > 0){
                    var adjuntos = [];
                    for (let j = 0; j < adj.length; j++){
                      adjuntos.push({ 'adj_nmro': adj[j].adj_nmro, 'adj_fecha': adj[j].dcm_fcharad, 'adj_nmbre': adj[j].adj_nombre, 'adj_nimg': adj[j].adj_nimg });
                    }

                    uri = '/docs/updateadjdoc';

                    this.axios.post(uri, { 'index': i/docs.length*100, 'cmp_id': this.$store.state.company, '_id': docs[i]._id, 'dcm_nmro': docs[i].dcm_nmro, 'adjuntos': adjuntos })
                    .then((response) => {
                      i++;
                      this.message = 'Registro [' + i + '/' + docs.length + '] actualizado';

                      if (i < docs.length){
                        start(docs, i);
                      }
                    })
                    .catch((err) => {
                      i++;
                      this.message = '¡Error al guardar el registro[' + i + '/' + docs.length + ']! // ' + err;
                      this.errores.push(err);

                      if (i < docs.length){
                        start(docs, i);
                      }
                    });
                  } else {
                    i++;
                    this.message = 'Registro [' + i + '/' + docs.length + '] procesado';

                    if (i < docs.length){
                      start(docs, i);
                    }
                  }
                })
                .catch((err) => {
                  i++;
                  this.message = '¡Error al guardar el registro[' + i + '/' + docs.length + ']! // ' + err;
                  this.errores.push(err);

                  if (i < docs.length){
                    start(docs, i);
                  }
                });                
              } else {
                //docs[i].dcm_expediente = null; //docs[i]._id;
                //docs[i].dcm_rlcion = null;
                i++;
                this.message = 'Registro [' + i + '/' + docs.length + '] guardado';

                if (i < docs.length){
                  start(docs, i);
                }
              }
          }

          start(docs, i);

          //this.message = 'Se actualizaron los datos secundarios!';
        })
        .catch((err) => {
          this.message = 'Error al actualizar los adjuntos!' + err;
        });

      },
      migrarDatosArchivos(){
        this.errores = [];
        this.message = 'Iniciando documentos - archivos!';
        let uri = '/docs/searcharchivos/' + this.$store.state.company;

        this.axios.get(uri)
        .then((response) => {
          var archivos = response.data;
          var i = 0;

          const start = (archivos, i) => {
            let src = '../repos/' + String(this.$store.state.company) + '/A000/' + String(Math.trunc(archivos[i].dcm_nmro/1000));
            let dst = '../repos/' + String(this.$store.state.company) + '/sdocs/' + Math.trunc(parseInt(archivos[i]._id.substring(0,8), 16)/1000000).toString() + '/' + String(archivos[i]._id);

            var tcr_cdgo = '';
            for (let j = 0; j < this.tpocors.length; j++){
              if (archivos[i].tcr_id == this.tpocors[j]._id){
                tcr_cdgo = this.tpocors[j].tcr_cdgo;
                j = this.tpocors.length;
              }
            }
            if (tcr_cdgo == ''){
              alert('Clase de documento no encontrada');
            }

            let uri = '/api/files/migrar';

            this.axios.post(uri, { 'index': i/archivos.length*100, 'src': src, 'dst': dst, 'dcm_nmro': archivos[i].dcm_nmro, 'tcr_cdgo': tcr_cdgo, 'dcm_ano': archivos[i].dcm_ano, 'dcm_cdgo': archivos[i].dcm_cdgo, 'adjuntos': archivos[i].adjuntos })
            .then((response) => {
              if (response.data.cambios.length > 0){
                let uri = '/docs/updatearchivosnombres';
                this.axios.post(uri, { 'cmp_id': this.$store.state.company, 'dcm_nmro': response.data.dcm_nmro, 'cambios': response.data.cambios })
                .then((response) => {
                  i++;
                  this.message = 'Registro [' + i + '/' + archivos.length + '] nombres cambiados! #: ' + response.data.dcm_nmro;
                  if (i < archivos.length){
                    start(archivos, i);
                  }
                })
                .catch((err) => {
                  i++;
                  this.message = 'Error al cambiar los nombres de archivos de documentos!' + err;
                  this.errores.push(err);
                  if (i < archivos.length){
                    start(archivos, i);
                  }
                })
              } else {
                i++;
                this.message = 'Registro [' + i + '/' + archivos.length + '] guardado';
                if (i < archivos.length){
                  start(archivos, i);
                }
              }
            })
            .catch((err2) => {
              i++;
              this.message = '¡Error al guardar el registro[' + i + '/' + archivos.length + ']! // ' + err2;
              this.errores.push(archivos[i].dcm_nmro);
              if (i < archivos.length){
                start(archivos, i);
              }
            });
          }

          start(archivos, i);
          
        })
        .catch((err) => {
          this.message = 'Error al actualizar los datos de archivos de documentos!' + err;
          this.errores.push(err);
        });
      },
      migrarTiff2Pdf(){
        this.errores = [];
        this.errores_conversion = [];
        this.message = 'Iniciando documentos - tiff a pdf!';
        let uri = '/docs/searcharchivostiff';

        this.axios.post(uri, { 'cmp_id': this.$store.state.company, 'limit1': this.limit1, 'limit2': this.limit2 })
        .then((response) => {
          if (response.data.length > 0){
            var archivos = response.data;
            var i = 0;

            const start = (archivos, i) => {
              var dst = '../repos/' + String(this.$store.state.company) + '/sdocs/' + Math.trunc(parseInt(archivos[i]._id.substring(0,8), 16)/1000000).toString() + '/' + String(archivos[i]._id);
              var adjuntos = archivos[i].adjuntos;

              var j = 0;

              const startAdjuntos = (adjuntos, j) => {
                var countDownDate = new Date().getTime();
                // Update the count down every 1 second
                var x = window.setInterval(function() {

                  // Get today's date and time
                  var now = new Date().getTime();
                    
                  // Find the distance between now and the count down date
                  var distance = Math.round((now - countDownDate)/1000);
                              
                  // Output the result in an element with id="time"
                  document.getElementById("time").innerHTML = 'Tiempo (seg): ' + distance;                    
                }, 1000);

                const resetInterval = (x) => {
                  window.clearInterval(x);
                  document.getElementById("time").innerHTML = "Tiempo (seg): TERMINADO";
                }
                                
                var ix = i + 1;
                var jx = j + 1;
                this.message = 'Registro [' + ix + '/' + archivos.length + '] - Convirtiendo archivo: [' + jx + '/' + adjuntos.length + ']';
                let uri = '/api/files/tiff2pdf';

                this.axios.post(uri, { 'dst': dst, 'dcm_nmro': archivos[i].dcm_nmro, 'adj_nmbre': adjuntos[j].adj_nmbre, 'monochrome': this.monochrome })
                .then((response) => {
                  let uri = '/docs/changetiff2pdf';

                  this.axios.post(uri, { 'cmp_id': this.$store.state.company, '_id': archivos[i]._id, 'dcm_nmro': archivos[i].dcm_nmro, 'adj_nmbre': adjuntos[j].adj_nmbre })
                  .then((response) => {
                    j++;
                    resetInterval(x);
                    this.message = 'Registro [' + i + '/' + archivos.length + '] - Archivo: [' + j + '/' + adjuntos.length + '] guardado';
                    if (j < adjuntos.length){
                      startAdjuntos(adjuntos, j);
                    } else {
                      i++;
                      this.message = 'Registro [' + i + '/' + archivos.length + '] completo';
                      if (i < archivos.length){
                        start(archivos, i);
                      }            
                    }
                  })
                  .catch((err) => {
                    j++;
                    resetInterval(x);
                    //this.message = '¡Error al guardar el registro[' + i + '/' + archivos.length + '] - Archivo: [' + j + '/' + adjuntos.length + '] // ' + err2;
                    this.errores.push(archivos[i].dcm_nmro);
                    if (j < adjuntos.length){
                      startAdjuntos(adjuntos, j);
                    } else {
                      i++;
                      this.message = 'Registro [' + i + '/' + archivos.length + '] completo';
                      if (i < archivos.length){
                        start(archivos, i);
                      }            
                    }
                  })
                })
                .catch((err) => {
                  j++;
                  resetInterval(x);
                  //this.message = '¡Error al guardar el registro[' + i + '/' + archivos.length + '] - Archivo: [' + j + '/' + adjuntos.length + '] // ' + err2;
                  this.error = err.response.data;
                  if (err.response.data.istiff){
                    if (err.response.data.existe){
                      if (!err.response.data.conversion){
                        this.message = '¡No se pudo convertir el archivo tiff' + archivos[i].dcm_nmro;
                        this.errores_conversion.push(archivos[i].dcm_nmro);
                      }
                    } else {
                      this.message = '¡No existe el archivo tiff' + archivos[i].dcm_nmro;
                      this.errores.push(archivos[i].dcm_nmro);
                    }
                  } else {
                    this.message = '¡El archivo ' + archivos[i].dcm_nmro + ' no es tiff';
                  }
                  if (j < adjuntos.length){
                    startAdjuntos(adjuntos, j);
                  } else {
                    i++;
                    this.message = 'Registro [' + i + '/' + archivos.length + '] completo';
                    if (i < archivos.length){
                      start(archivos, i);
                    }            
                  }      
                });
              }

              startAdjuntos(adjuntos, j);

            }

            start(archivos, i);            
          } else {
            this.message = 'LA CONSULTA NO OBTUVO RESULTADOS';
          }
        })
        .catch((err) => {
          this.message = 'Error al consultar los datos de documentos! // ' + err;
          this.errores.push(err);
        });
      },
      migrarChangeNameTiff2Pdf(){
        this.errores = [];
        this.message = 'Iniciando documentos - Cambiar nombre tiff a pdf!';
        let uri = '/docs/searcharchivosnametiff';

        this.axios.post(uri, { 'cmp_id': this.$store.state.company })
        .then((response) => {
          var archivos = response.data;
          var i = 0;

          const start = (archivos, i) => {
            var dst = '../repos/' + String(this.$store.state.company) + '/sdocs/' + Math.trunc(parseInt(archivos[i]._id.substring(0,8), 16)/1000000).toString() + '/' + String(archivos[i]._id);
            var adjuntos = archivos[i].adjuntos;

            var j = 0;

            const startAdjuntos = (adjuntos, j) => {
              var ix = i + 1;
              var jx = j + 1;
              this.message = 'Registro [' + ix + '/' + archivos.length + '] - Cambiando nombre de archivo: [' + jx + '/' + adjuntos.length + ']';

              let uri = '/api/files/existspdf';

              this.axios.post(uri, { 'dst': dst, 'dcm_nmro': archivos[i].dcm_nmro, 'adj_nmbre': adjuntos[j].adj_nmbre })
              .then((response) => {

                let uri = '/docs/changetiff2pdf';

                this.axios.post(uri, { 'cmp_id': this.$store.state.company, '_id': archivos[i]._id, 'dcm_nmro': archivos[i].dcm_nmro, 'adj_nmbre': adjuntos[j].adj_nmbre })
                .then((response) => {
                  j++;
                  this.message = 'Registro [' + i + '/' + archivos.length + '] - Archivo: [' + j + '/' + adjuntos.length + '] guardado';
                  if (j < adjuntos.length){
                    startAdjuntos(adjuntos, j);
                  } else {
                    i++;
                    this.message = 'Registro [' + i + '/' + archivos.length + '] completo';
                    if (i < archivos.length){
                      start(archivos, i);
                    }            
                  }
                })
                .catch((err) => {
                  j++;
                  //this.message = '¡Error al guardar el registro[' + i + '/' + archivos.length + '] - Archivo: [' + j + '/' + adjuntos.length + '] // ' + err2;
                  this.errores.push(archivos[i].dcm_nmro);
                  if (j < adjuntos.length){
                    startAdjuntos(adjuntos, j);
                  } else {
                    i++;
                    this.message = 'Registro [' + i + '/' + archivos.length + '] completo';
                    if (i < archivos.length){
                      start(archivos, i);
                    }            
                  }
                });
              })
              .catch((err) => {
                j++;
                this.error = err.response.data;
                if (err.response.data.istiff && !err.response.data.existspdf){
                  this.message = '¡No existe el archivo pdf' + archivos[i].dcm_nmro;
                  this.errores.push(archivos[i].dcm_nmro);
                } else {
                  this.message = '¡El archivo ' + archivos[i].dcm_nmro + ' existe o no es tiff';
                }
                if (j < adjuntos.length){
                  startAdjuntos(adjuntos, j);
                } else {
                  i++;
                  this.message = 'Registro [' + i + '/' + archivos.length + '] completo';
                  if (i < archivos.length){
                    start(archivos, i);
                  }            
                }      
              });

            }

            startAdjuntos(adjuntos, j);

          }

          start(archivos, i);
          
        })
        .catch((err) => {
          this.message = 'Error al actualizar los datos de archivos de documentos!' + err;
          this.errores.push(err);
        });
      },
      migrarEliminarTiff(){
        this.errores = [];
        this.tiff_eliminados = [];
        this.message = 'Iniciando documentos - archivos!';
        let uri = '/docs/searcharchivosdeletetiff';

        this.axios.post(uri, { 'cmp_id': this.$store.state.company })
        .then((response) => {
          if (response.data.length > 0){
            var archivos = response.data;
            var i = 0;

            const start = (archivos, i) => {
              var dst = '../repos/' + String(this.$store.state.company) + '/sdocs/' + Math.trunc(parseInt(archivos[i]._id.substring(0,8), 16)/1000000).toString() + '/' + String(archivos[i]._id);
              var adjuntos = archivos[i].adjuntos;

              var j = 0;

              const startAdjuntos = (adjuntos, j) => {
                var ix = i + 1;
                var jx = j + 1;
                this.message = 'Registro [' + ix + '/' + archivos.length + '] - Revisando archivo: [' + jx + '/' + adjuntos.length + ']';
                let uri = '/api/files/deletetiff';

                this.axios.post(uri, { 'dst': dst, 'dcm_nmro': archivos[i].dcm_nmro, 'adj_nmbre': adjuntos[j].adj_nmbre })
                .then((response) => {
                  this.message = '¡Archivo tiff eliminado' + archivos[i].dcm_nmro;
                  this.tiff_eliminados.push(archivos[i].dcm_nmro);
                  
                  j++;
                  this.message = 'Registro [' + i + '/' + archivos.length + '] - Archivo: [' + j + '/' + adjuntos.length + '] guardado';
                  if (j < adjuntos.length){
                    startAdjuntos(adjuntos, j);
                  } else {
                    i++;
                    this.message = 'Registro [' + i + '/' + archivos.length + '] completo';
                    if (i < archivos.length){
                      start(archivos, i);
                    }            
                  }
                })
                .catch((err) => {
                  j++;
                  //this.message = '¡Error al guardar el registro[' + i + '/' + archivos.length + '] - Archivo: [' + j + '/' + adjuntos.length + '] // ' + err2;
                  this.error = err.response.data;
                  if (err.response.data.ispdf){
                    if (err.response.data.existspdf){
                      if (err.response.data.existstiff){
                        if (!err.response.data.deleted){
                          this.message = '¡Archivo tiff no fue eliminado' + archivos[i].dcm_nmro;
                          this.errores.push(archivos[i].dcm_nmro);
                        }
                      } else {
                        this.message = '¡No existe el archivo tiff' + archivos[i].dcm_nmro;
                      }
                    } else {
                      this.message = '¡No existe el archivo pdf' + archivos[i].dcm_nmro;
                    }
                  } else {
                    this.message = '¡El archivo ' + archivos[i].dcm_nmro + ' no es pdf';
                  }
                  if (j < adjuntos.length){
                    startAdjuntos(adjuntos, j);
                  } else {
                    i++;
                    this.message = 'Registro [' + i + '/' + archivos.length + '] completo';
                    if (i < archivos.length){
                      start(archivos, i);
                    }            
                  }      
                });
                
              }

              startAdjuntos(adjuntos, j);

            }

            start(archivos, i);            
          } else {
            this.message = 'LA CONSULTA NO OBTUVO RESULTADOS';
          }
        })
        .catch((err) => {
          this.message = 'Error al consultar los datos de archivos de documentos!' + err;
          this.errores.push(err);
        });
      },
      cargarAdjuntos(){        
        this.errores = [];
        this.errores_conversion = [];
        this.message = 'Iniciando documentos - cargar adjuntos!';

        if(!this.carpeta){
          alert('Por favor indique la carpeta donde están los archivos adjuntos.');
          return;
        }

        let uri = '/docs/cargar_adjuntos';

        this.axios.post(uri, { 'cmp_id': this.$store.state.company, 'adj_tcr_id': this.adj_tcr_id, 'adj_dcm_ano': this.adj_dcm_ano, 'adjuntos1': this.adjuntos1, 'adjuntos2': this.adjuntos2 })
        .then((response) => {
          var docs = response.data;

          if (docs.length > 0){            
            const start = (docs, i) => {
              let uri = '/api/cargar_adjuntos';
              
              this.axios.post(uri, { 'cmp_id': this.$store.state.company, 'index': i, 'doc': docs[i], 'carpeta': this.carpeta, 'usu_id': this.$store.state.user, 'tcr_nmbre': this.$refs.adj_tcr_id.options[this.$refs.adj_tcr_id.selectedIndex].text, 'dcm_ano': this.adj_dcm_ano })
              .then((response) => {
                next_item(docs, i);
              })
              .catch((err) => {
                this.errores.push(err.response.data.message);
                next_item(docs, i);
              });
              
            }

            const next_item = (docs, i) => {
              i++;
              this.message = 'Registro (' + i + '/' + docs.length + ') completo';
              if (i < docs.length){
                start(docs, i);
              } else {
                //alert('Proceso terminado');
              }
            }
            
            start(docs, 0);
          } else {
            alert('El rango de documentos no es válido.');
          }
        })
        .catch((err) => {
          this.errores.push(err);
          alert('Error al cargar los documentos.');
        });    
      },

      migrarChangeNameComfacasanare(){        
        this.errores = [];
        this.errores_conversion = [];
        this.message = 'Iniciando documentos - cambiando nombres!';

        let uri = '/docs/buscar_archivos_comfacasanare';

        this.axios.post(uri, { 'cmp_id': this.$store.state.company })
        .then((response) => {
          var docs = response.data;

          if (docs.length > 0){            
            const start = (docs, i) => {
              let uri = '/docs/cambiar_archivos_comfacasanare';
              
              this.axios.post(uri, { 'cmp_id': this.$store.state.company, 'index': i, '_id': docs[i]._id })
              .then((response) => {
                next_item(docs, i);
              })
              .catch((err) => {
                this.errores.push(err.response.data.message);
                next_item(docs, i);
              });
              
            }

            const next_item = (docs, i) => {
              i++;
              this.message = 'Registro (' + i + '/' + docs.length + ') completo';
              if (i < docs.length){
                start(docs, i);
              } else {
                //alert('Proceso terminado');
              }
            }
            
            start(docs, 0);
          } else {
            alert('El rango de documentos no es válido.');
          }
        })
        .catch((err) => {
          this.errores.push(err);
          alert('Error al cambiar los nombres de documento.');
        });    
      }


    }// END METHODS
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>
